.body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    width: auto;
    margin: 10px auto;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .formWrapper {
    max-width: 400px;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formControl {
    margin-bottom: 10px;
  }
  
  .errorMsg {
    max-width: 350px;
    color: #f21e08;
    padding: 2px 0;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 300;
  }
  
  .formControl {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .formControl label {
    width: 50%;
    padding-right: 20px;
    margin-bottom: 5px;
  }
  
  .formControl input[type="text"],
  .formControl input[type="password"] {
    width: 100%;
    padding: 5px;
    height: 50px;
    background-color: #ebebeb !important ;
  }
  
  .button {
    max-width: 150px;
    padding: 5px 10px;
  }
  
  @media screen and (max-width: 600px) {
    .body {
      width: auto;
    }
  
    .errorMsg {
      max-width: 100%;
    }
  
    .formControl {
      flex-direction: column;
    }
  
    .formControl input[type="text"],
    .formControl input[type="password"] {
      width: 100%;
    }
  }
  